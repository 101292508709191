import React from 'react';
import { ResumeQuizButtonWrapper } from '@hungryroot/exp-138-bypass-quiz-intro';
import { Heading, HeadingVariant } from '@hungryroot/ramen';
import { ArrowRight } from '@hungryroot/icons';

import styles from './TakeTheQuizLink.module.css';

export function TakeQuizLink({ className }: { className?: string }) {
  return (
    <Heading className={className} variant={HeadingVariant.H6}>
      <ResumeQuizButtonWrapper>
        {({ route }) => {
          return (
            <a className={styles.link} href={route}>
              Take the quiz <ArrowRight />
            </a>
          );
        }}
      </ResumeQuizButtonWrapper>
    </Heading>
  );
}
